<template>
  <div class="relative">
    <BackFr class="top-5 -right-4 absolute" v-if="isLtr" />
    <BackFa v-if="isRtl" />
    <div class="mt-10 flex justify-center items-center">
      <div>
        <img
          class="h-auto image-slide"
          v-if="step === 1"
          src="../assets/images/pic-2.png"
          alt=""
        />
        <img
          class="h-auto image-slide"
          v-if="step === 2"
          src="../assets/images/pic-2.png"
          alt=""
        />
      </div>
      <div class="mx-5">
        <div
          @click="step = 1"
          :class="{ 'bg-cyan-300': step === 1 }"
          class="cursor-pointer mb-3 h-12 w-12 rounded-full bg-primary font-black text-2xl text-white pt-1 flex items-center justify-center"
        >
          1
        </div>
        <div
          @click="step = 2"
          :class="{ 'bg-cyan-300': step === 2 }"
          class="cursor-pointer mb-3 h-12 w-12 rounded-full bg-primary font-black text-2xl text-white pt-1 flex items-center justify-center"
        >
          2
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackFa from '../components/back/BackFa.vue'
import BackFr from '../components/back/BackFr.vue'
export default {
  name: 'SlideHeart',
  components: { BackFa, BackFr },
  data() {
    return {
      step: 1,
    }
  },
}
</script>

<style></style>
